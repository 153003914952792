import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

export default () => (
  <Layout>
    <SEO
      title="Angajăm tehnician telecomunicații | Jonctor fibră optică"
      description="Candidatul ideal are experiență în joncțiunea / sudarea cablurilor de
          fibră optică si va executa lucrări de mentenanță, instalare, intervenții, remediere
            și joncționare cablu de fibra optică"
    />
    <Content>
      <h1>Tehnician Telecomunicații</h1>
      <div className="alert alert-info" role="alert">
        Posturi vacante {" — "}
        <Link to="/contact" className="alert-link">
          Aplică acum!
        </Link>
      </div>
      <h3>Jonctor fibră optică</h3>
      <div>
        <p className="lead">
          Candidatul ideal are experiență în joncțiunea / sudarea cablurilor de
          fibră optică
        </p>
        <strong>Cerințe</strong>
        <ul>
          <li>
            să execute lucrări de mentenanță, instalare, intervenții, remediere
            și joncționare cablu de fibra optică
          </li>
          <li>studii medii sau superioare cu profil tehnic</li>
          <li>
            curs de specializare în joncționare fibre optice - constituie un
            avantaj
          </li>
          <li>
            cunoștințe de limba engleză sau / si franceză - constituie un
            avantaj
          </li>
          <li>permis categoria B - constituie un avantaj</li>
          <li>
            spirit de echipa, de initiativa si de coordonare cu ceilalți membri
            ai echipei
          </li>
        </ul>
        <strong>Beneficii</strong>
        <ul>
          <li>loc de muncă stabil</li>
          <li>pachet salarial atractiv</li>
          <li>pregătire la locul de muncă, echipament de lucru</li>
          <li>
            în caz de deplasare se asigură diurnă și transport de la cazare la
            locul de muncă cu autovehiculele firmei, iar cazarea este asigurată
            de societate
          </li>
        </ul>
        <strong>Descrierea companiei</strong>
        <p>
          Telecommunication Networks SRL este o companie românească cu filială
          în Franța ce execută lucrări de construcții rețele de fibră optică,
          întreținere a rețelelor de cablu și instalare echipamente de
          telecomunicații.
        </p>
        <h3>Linior telecomunicații</h3>
        <p>
          Dacă ai experiență în postul de tehnician telecomunicații și dorești
          să ni te alături, te rugăm să ne{" "}
          <Link to={"/contact"}>contactezi</Link> pentru a-ți oferi toate
          amănuntele legate de viitorul tău job.
        </p>
      </div>
    </Content>
  </Layout>
)
